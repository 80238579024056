// @flow

import * as React from 'react'
import PrePuriticationSvg from './assets/icon_pre_purification.svg'
import PuriticationBindSvg from './assets/icon_purification_bind.svg'
import PuriticationWashSvg from './assets/icon_purification_wash.svg'
import PuriticationEluteSvg from './assets/icon_purification_elute.svg'

import * as styles from './ProteinPurification.module.css'

export const CONTENT_INFO = (
  <div>
    <p className={styles.p}>
      With the OT-2 Magnetic Bead Protein Purification Workstation, you can
      automate your small-scale protein purification and proteomics sample
      processing. This Opentrons Protein Purification Workstation uses magnetic
      beads for purification of proteins.
      <br />
    </p>
  </div>
)

export const OVERVIEW_DATA = {
  workflowCompatibility:
    'This workstation can support several workflows including:',
  listBulletpoint: [
    'Enzymatic and chemical lysis',
    'Magnetic bead-based purification',
    'SP3 sample preparation for LC-MS',
    'Protein labeling',
    'BCA and Bradford assay',
    'SDS and native PAGE sample preparation',
  ],
}

export const LINKS = {
  Overview: {
    title: 'Overview',
    scrollId: 'overview',
  },
  WorkstationComponents: {
    title: 'Workstation Components',
    scrollId: 'materials',
  },
  ApplicationNotes: {
    title: 'Application Notes',
    scrollId: 'notes',
  },
  Protocols: {
    title: 'Protocols',
    scrollId: 'protocols',
  },
}

export const MENU_DATA = {
  menuData: {
    links: [
      LINKS.Overview.title,
      LINKS.WorkstationComponents.title,
      LINKS.ApplicationNotes.title,
      LINKS.Protocols.title,
    ],
    linkScrollIds: {
      [LINKS.Overview.title]: LINKS.Overview.scrollId,
      [LINKS.WorkstationComponents.title]: LINKS.WorkstationComponents.scrollId,
      [LINKS.ApplicationNotes.title]: LINKS.ApplicationNotes.scrollId,
      [LINKS.Protocols.title]: LINKS.Protocols.scrollId,
    },
    url: '/cleanupkit',
    workflow: 'Interested in automating your protein purification?',
  },
  gtmCategory: 'b-workstation',
  protocolGtmLabel: 'schedule-consultation',
  demoGtmLabel: 'schedule-demo',
}

export const SCROLL_MENU_DATA = [
  LINKS.Overview.scrollId,
  LINKS.WorkstationComponents.scrollId,
  LINKS.Protocols.scrollId,
]

export const PROTOCOLS_DATA = {
  description: '',
  hideTable: true,
  hideDisclosures: true,
  bulletListDescription:
    'Check out example protocols below, or browse all protein purification library prep protocols <a style="color: #006fff; font-weight: 600;" href="https://protocols.opentrons.com/categories/Protein%20Purification">here</a>',
  bulletItems: [
    {
      title:
        'Protein purification using Thermo Fisher Pierce Ni-NTA Magnetic Agarose Beads',
      url: 'https://protocols.opentrons.com/categories/Protein%20Purification/Thermo%20Fisher%20Pierce%20Ni-NTA%20Magnetic%20Agarose%20Beads',
    },
    {
      title: 'Protein purification using Thermo Fisher Dynabeads™ Protein A/G',
      url: 'https://protocols.opentrons.com/categories/Protein%20Purification/Thermo%20Fisher%20Dynabeads%E2%84%A2%20Protein%20A%2FG',
    },
  ],
}

export const LABWARE_URL = 'https://labware.opentrons.com/'

export const RELATED_CONTENT_DATA = [
  {
    url: 'https://blog.opentrons.com/why-you-should-automate-nucleic-acid-preparation/',
    readInfo: 'BLOG - 5 MIN READ',
    title: `Why You Should Automate Nucleic Acid Preparation`,
    content:
      'Nucleic acid isolation is a cornerstone technique in molecular biology. Many labs use manual purification protocols for this, but they have four downsides.',
  },
  {
    url: 'https://blog.opentrons.com/ngs-automation/',
    readInfo: 'BLOG - 4 MIN READ',
    title: `5 Reasons to Automate your NGS Workflow`,
    content:
      'With greater speed, lower costs, and higher throughput than first generation methods, next generation sequencing (NGS) has catapulted biological research into a new era.',
  },
]

export const MATERIAL_DESCRIPTION =
  'The OT-2 Magnetic Bead Protein Purification Workstation includes all the hardware and consumables you need to perform automated protein purification. It comes with Opentrons’ protocol development service, which provides you with a customized protocol unique to your assay. You’ll also enjoy free access to the Opentrons Protocol Designer, Python API, and Protocol Library.'

export const MATERIAL_DATA = [
  {
    imgSrc: require('../../images/application/materials/opentrons_ot2.jpg') //
      .default,
    name: 'OT-2 Liquid Handling Robot',
  },
  {
    imgSrc: require('./assets/heatshaker.png').default, //
    name: 'Heater-Shaker Module',
  },
  {
    imgSrc: require('./assets/deepwell_adapter.png').default, //
    name: 'Heater-Shaker Deep Well Adapter',
  },
  {
    imgSrc: require('./assets/pcr_adapter.png').default, //
    name: 'Heater-Shaker PCR Adapter',
  },
  {
    imgSrc: require('./assets/magnetic_module_gen2.png').default, //
    name: 'Magnetic Module',
  },
  {
    imgSrc: require('./assets/P300M.png').default, //
    name: 'P300 Multi-Channel Pipette',
  },
  {
    imgSrc: require('./assets/P300S.png').default, //
    name: 'P300 Single-Channel Pipette',
  },
  {
    imgSrc: require('./assets/15x15_RackStand.png').default, //
    name: '4-in-1 Tube Rack Set',
  },
  {
    imgSrc: require('./assets/300ul_tips.png').default, //
    name: 'Opentrons 300 μl Tips Racks (2 x 9600 tips)',
  },
  {
    imgSrc: require('./assets/nest_12well_reservoir.png').default, //
    name: 'NEST 12-Well Reservoir 15 ml (50)',
  },
  {
    imgSrc: require('./assets/nest_1well_reservoir.png').default, //
    name: 'NEST 1-Well Reservoirs (50)',
  },
  {
    imgSrc: require('./assets/tough_0-2ml_96well_pcr_plate.png').default, //
    name: 'Tough 0.2 mL 96-Well PCR Plates (25)',
  },
  {
    imgSrc: require('./assets/nest_2ml_96well_deep.png').default, //
    name: 'NEST 2 mL Deep Well Plates (50)',
  },
  {
    imgSrc: require('./assets/15ml_centrifuge_tube.png').default, //
    name: 'NEST 15 mL Centrifuge Tubes (500)',
  },
  {
    name: 'Custom protocol development (standard turnaround time)',
  },
]

export const WORKFLOW_DESCRIPTION =
  'The Opentrons Protein Purification Workstation supports low- to medium-throughput workflows using column chromatography protein purification. Below is an overview of an Opentrons protein purification assay, information on what the workstations include, an application note featuring this workstation, your protocol development options, and other information you may need for your assay.'

export const WORKFLOW_DATA = [
  {
    imgSrc: PrePuriticationSvg,
    name: 'Pre-Purification',
    content: 'Cell lysate before purification',
  },
  {
    imgSrc: PuriticationBindSvg,
    name: 'Bind',
    content:
      'Add magnetic beads and load them on the OT-2 robot magnet. Process until supernatant is ready to be removed.',
  },
  {
    imgSrc: PuriticationWashSvg,
    name: 'Wash',
    content:
      'Wash off impurities with a wash and/or strip buffer. Process until the protein of interest is released.',
  },
  {
    imgSrc: PuriticationEluteSvg,
    name: 'Elute',
    content: 'Elute desired material.',
  },
]

export const BENEFIT_DATA = {
  benefitList: [
    'Maximum accuracy, precision and reproducibility',
    'Increased hands-free and walk-away time',
    'Best automation ROI',
    'Works with any reagent or bead based product used in Protein Purification',
  ],
  benefitContent:
    'Automating protein purification improves the quality of your assay while reducing errors, assay reruns, and uncontrolled variables.  The Opentrons Protein Purification Workstation provides your assay:',
  imageItalicText: 'Closeup of beads pelleting on the OT-2 magnetic module',
}

export const APPLICATION_DATA_DATA = {
  italicText: `Purification of 20 µL of indexed 16S Metagenomic and ladder samples with 1X and 1.8X bead volumes to show OT-2 size selection on Agilent TapeStation High Sensitivity D1000 ScreenTape®`,
  imgSrc: require('./assets/cleanup-kit-graph-01-1200-px@2x.png').default,
}

export const RESOURCE_NOTE_DATA = [
  {
    resource: 'application note',
    contentText: 'Pending new application note',
    contentLink: '',
    iconSrc: 'pdf-download-light-gray',
    validationIcon: true,
  },
  {
    resource: 'video',
    contentText: 'Magnetic Module',
    contentLink: 'https://vimeo.com/299671341',
    iconSrc: 'video-sample',
  },
  {
    resource: 'application note',
    contentText: 'Pending new application note',
    contentLink: '',
    iconSrc: 'pdf-download-light-gray',
  },
  {
    resource: 'protocol',
    contentText: 'Pending new protocol',
    contentLink: '',
    iconSrc: 'pdf-download-light-gray',
  },
]

export const NOTE_DATA = [
  {
    note: (
      <a
        className={styles.note_link}
        href="https://insights.opentrons.com/hubfs/Applications/Protein%20purification/Immunoprecipitation%20using%20Thermo%20Fisher%20Dynabeads%20on%20the%20OT-2.pdf"
      >
        Immunoprecipitation using Thermo Fisher Dynabeads on the OT-2
      </a>
    ),
  },
  {
    note: (
      <a
        className={styles.note_link}
        href="https://insights.opentrons.com/hubfs/Applications/Protein%20purification/Automated%20Ni-NTA%20Protein%20Purification%20App%20Note.pdf"
      >
        Automated Ni-NTA bead-based protein purification on the OT-2 liquid
        handler
      </a>
    ),
  },
  {
    note: (
      <a
        className={styles.note_link}
        href="https://insights.opentrons.com/hubfs/Applications/Protein%20purification/Automating%20Sample%20Prep%20for%20MS-based%20Proteomics%20Case%20Study.pdf"
      >
        Case Study: Automating Complex Manual Sample Preparation for
        MassSpectrometry-Based Proteomics Using the Opentrons OT-2 Platform
      </a>
    ),
  },
]
