// @flow

import * as React from 'react'

import Hero from './Hero'

import {
  Overview,
  StickyMenuDynamic,
  Notes,
  OpentronsMaterial,
} from '../../ui-components'

import PROTEINPURIFICATIONHERO from './assets/Protein_Purification_(Mag_Bead)_Workstation.png'

import {
  OVERVIEW_DATA,
  MATERIAL_DATA,
  MATERIAL_DESCRIPTION,
  SCROLL_MENU_DATA,
  MENU_DATA,
  CONTENT_INFO,
  PROTOCOLS_DATA,
  NOTE_DATA,
  LINKS,
} from './data'

import * as styles from './ProteinPurification.module.css'

import OpentronsProtocols from '../../ui-components/application-page/Protocols'

export default function ProteinPurificationChromatography() {
  return (
    <>
      <Hero
        imgSrc={PROTEINPURIFICATIONHERO}
        mobileImgSrc={PROTEINPURIFICATIONHERO}
      />
      <div className={styles.application_container}>
        <StickyMenuDynamic
          {...MENU_DATA}
          scrollSpyItems={SCROLL_MENU_DATA}
          resetMenu={true}
          buttonOneText="DOWNLOAD FLYER"
          buttonOneLink="https://insights.opentrons.com/hubfs/Products/Workstations/Magnetic%20Bead%20Based%20Protein%20Purification%20Workstation%20One%20Sheeter.pdf"
        />
        <div className={styles.content_container}>
          <Overview content={CONTENT_INFO} {...OVERVIEW_DATA} />
          <OpentronsMaterial
            title="Workstation Components"
            description={MATERIAL_DESCRIPTION}
            materialItems={MATERIAL_DATA}
            id={LINKS.WorkstationComponents.scrollId}
          />
          <Notes title="Application Notes" noteItems={NOTE_DATA} />
          <OpentronsProtocols
            {...PROTOCOLS_DATA}
            title={LINKS.Protocols.title}
            id={LINKS.Protocols.scrollId}
          />
        </div>
      </div>
    </>
  )
}
