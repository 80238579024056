import React from 'react'
import Page from '../../components/Page'
import SEO from '../../components/seo'
import ProteinPurificationBeadBased from '../../components/ProteinPurificationBeadBased'

const ProteinPurificationBeadBasedPage = () => (
  <Page>
    <SEO title="Protein Bead Based" description="Protein Bead Based" />
    <ProteinPurificationBeadBased />
  </Page>
)

export default ProteinPurificationBeadBasedPage
