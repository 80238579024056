// extracted by mini-css-extract-plugin
export var p = "ProteinPurification-module--p--Df1mx";
export var h2 = "ProteinPurification-module--h2--N0Hbl";
export var desktop_container = "ProteinPurification-module--desktop_container--U1DXO";
export var h4 = "ProteinPurification-module--h4--0T+E7";
export var clean_up_paragraph = "ProteinPurification-module--clean_up_paragraph--3Rupa";
export var application_container = "ProteinPurification-module--application_container--s8AqU";
export var container = "ProteinPurification-module--container--t4GAI";
export var button = "ProteinPurification-module--button--K8CP6";
export var mobile_image = "ProteinPurification-module--mobile_image--rGewg";
export var image = "ProteinPurification-module--image--ZheGv";
export var badge = "ProteinPurification-module--badge--1adBe";
export var bg_color = "ProteinPurification-module--bg_color--yLxU+";
export var hero_container = "ProteinPurification-module--hero_container--5nVPW";
export var content_container = "ProteinPurification-module--content_container--QJjlI";
export var shop_item_container = "ProteinPurification-module--shop_item_container--GEECV";
export var resource_span = "ProteinPurification-module--resource_span--iFo6-";
export var resource_link_container = "ProteinPurification-module--resource_link_container--5CzZs";
export var icon = "ProteinPurification-module--icon--5UgGV";
export var icon_image = "ProteinPurification-module--icon_image--ZLcXJ";
export var resource_link = "ProteinPurification-module--resource_link--WSTNl";
export var resource_note_container = "ProteinPurification-module--resource_note_container--eKPrC";
export var bold_style = "ProteinPurification-module--bold_style--Rjfxt";
export var italic_style = "ProteinPurification-module--italic_style--CtiB+";
export var mobile_view = "ProteinPurification-module--mobile_view--Znygi";
export var desktop_view = "ProteinPurification-module--desktop_view--z5th1";
export var grid = "ProteinPurification-module--grid--8+yLZ";
export var grid_header = "ProteinPurification-module--grid_header--pdlY6";
export var grid_item = "ProteinPurification-module--grid_item--o2V7s";
export var services_table = "ProteinPurification-module--services_table--fdryy";
export var verification_data = "ProteinPurification-module--verification_data--0vQDV";
export var figure_text = "ProteinPurification-module--figure_text--+wduo";
export var figure_image = "ProteinPurification-module--figure_image--VOass";
export var verification_header = "ProteinPurification-module--verification_header--fdMqt";
export var verification_section = "ProteinPurification-module--verification_section--m6g9L";
export var badge_icon = "ProteinPurification-module--badge_icon--srtKx";
export var note_link = "ProteinPurification-module--note_link--OQ7H2";